<template>
    <section class="">
        <div class="active-chat w-100">
            <b-row class="mb-5">
                <b-col cols="12">
                    <b-button variant="primary" type="button" :to="{name: 'user-ai-images'}">
                        Generate Image
                    </b-button>
                </b-col>
            </b-row>
            
            <b-row class="mb-5">
                <b-col cols="12">
                    <b-form class="chat-app-form" @submit.prevent="searchSavedImage">
                        <b-row>
                            <b-col cols="12" md="8">
                                <b-input-group class="input-group-merge form-send-message mr-1 mb-2">
                                    <b-form-input :disabled="loading" v-model="query" name="find_image" placeholder="Search image" />
                                </b-input-group>
                            </b-col>
                            <b-col cols="12" md="4">
                                <b-button variant="primary" type="submit" class="mr-1" :disabled="isEmpty(query) || loading">
                                    Search
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-form>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12">
                    <b-row class="mb-5">
                        <b-col cols="12" md="3" v-for="(image, ik) in allSavedImages" :key="`saved_images_${ik}`" class="mb-1">
                            <GeneratedImage :image="image.image" :aiImage="image.aiImage" />
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            <b-row class="mb-5">
                <b-col cols="12" class="d-flex justify-content-center">
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="1"
                        first-text="First"
                        prev-text="Prev"
                        next-text="Next"
                        last-text="Last"
                    />
                </b-col>
            </b-row>
        </div>
    </section>
</template>
  
<script>
import { mapActions, mapGetters } from 'vuex'
import { isEmpty } from '@/utilities'
import GeneratedImage from '@/views/users/ai/components/GeneratedImage.vue'
import { hideLoader, showLoader } from '@/@core/comp-functions/ui/app'
export default {
    name: 'AISavedImages',

    components: {
        GeneratedImage
    },

    props: {

    },

    data: () => ({
        loading: false,
        aiImages: [],
        savedImages: [],
        currentPage: 1,
        query: null,
        old_query: null,
        rows: null,
    }),

    computed: {
        allSavedImages: {
            get(){
                const images = []
                this.savedImages.forEach(i => {
                    i.saved_images.forEach(j => {
                        images.push({
                            aiImage: i,
                            image: j
                        })
                    })
                })
                return images
            }
        },
    },
    mounted() {
        this._getSavedImages()
    },
    methods: {
        isEmpty,
        ...mapActions('ai', [
            'getSavedImages',
        ]),
        async searchSavedImage(){
            const oldQuery = typeof this.old_query == 'string' ? this.old_query.trim() : null;
            const query = typeof this.query == 'string' ? this.query.trim() : null;
            if(query != oldQuery){
                this.currentPage = 1
                this.old_query = this.query
            }
            this._getSavedImages()
        },
        async _getSavedImages(){
            showLoader()
            this.loading = true
            await this.getSavedImages({
                query: this.query,
                page: this.currentPage,
            }).then(data => {
                if(!isEmpty(data.responseData)){
                    const paginatedData = data.responseData
                    console.log(paginatedData, 'paginatedData');
                    const {data: images, total} = paginatedData
                    const imagesIds = images.map(i => i.id)
                    this.rows = total
                    this.savedImages = images
                }
            })
            this.loading = false
            hideLoader()
        }
    },
    watch: {
        currentPage: {
            handler(){
                this._getSavedImages()
            },
            deep: true,
            immediate: true
        }
    }
}
</script>
